
# Note that in dev this won't automatically recompile 



window.Fun ||= {}

window.Fun.app = "workroster"

window.translations = {"color":"colour","html-lang":"en-AU","html_lang":"en-AU","FUN_APP":"workroster","rota":"roster","rotas":"rosters","Rota":"Roster","Rotas":"Rosters","Rotaville":"WorkRoster","rotaville":"workroster","rotaville_com":"workroster.com","Rotaville_com":"WorkRoster.com","ROTAVILLE":"WORKROSTER","R":"W","Revolutionise":"Revolutionise","Optimise":"Optimise","aged_care":"aged care","rota_planning":"Roster Planning","rota_management":"Roster Management"}

window.locale_loaded = "en_WORKROSTER"
  
window.t = (key) ->
  if translations[key]
    return translations[key]
  else
    error_string = "MISSING_TRANSLATION(#{key})"
    console?.log error_string
    return error_string

