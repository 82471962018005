/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'external' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require('../fun/sentry_init.js')

require('../fun/external_globals.js')
require('../fun/app_scope.js.coffee')

require('../fun/vendor/backbone-rotaville-1.4.js')
// require('../fun/backbone_sync.js')
require('../fun/translations.js.coffee.erb')
require('../fun/jtip.js')
require('../fun/jlSlider.js')
require('../fun/vendor/rails.js')
require('../fun/general.js')
require('../fun/start_toastie.js')
require('../fun/start_bs_modal.js')
require('../fun/jsx/video_modal.js')
require('../fun/jsx/pic_modal.js')
// require('../fun/channels/guest_channel.js')
// require('../fun/models/external_index.js')
require('../fun/models/external_slim_index.js')
// require('../fun/start_chat_widget.js')
require('../fun/jready.js.coffee')

